import "./CropEditing.css";
import React, {useCallback, useMemo, useState, useEffect} from "react";
import { FiEdit3, FiSave } from "react-icons/fi";
import {deconstructPlaybackUrl} from "../../../../../utility/Utilities";
import {useVideoPlayer} from "../../../../../components/VideoPlayer/VideoPlayerProvider";
import VideoOverlay from "../../../../../components/VideoPlayer/VideoOverlay/VideoOverlay";
import {secToFrame, useFieldVisionData} from "../../../../../utility/useFieldVisionData";
import {VisualizePOI} from "../../../../../components/VideoPlayer/VideoOverlay/VisualizePOI";
import Config from "../../../../../utility/Config";
import Backend from "../../../../../utility/Backend";
import savitzkyGolay from 'ml-savitzky-golay';
import EditingTimelineNumber from "../EditingTimelineNumber";
import ConfirmModal from "../../../../../components/ConfirmModal";
import classNames from "classnames";
import { RxReset } from "react-icons/rx";

function PoiOverlays ({
    editsMap,
    interpolatedMap,
    isEditing,
    aspectRatio,
    assetId,
    assetTimeOffsetMs,
    fps=Config.expectedFrameRate,
    // graphType=[]
}) { 

    const [override, setOverride] = useState(null)
    const [editingPoi, setEditingPoi] = useState(false)

    const {getCurrentTime, playerRef} = useVideoPlayer()
    
    const frameNumber = secToFrame(getCurrentTime() + (assetTimeOffsetMs / 1000), fps)
    const originalPoi = useFieldVisionData("poi", frameNumber, getCurrentTime, assetId, assetTimeOffsetMs, fps)
    // The list/full map of original POI
    const originalPoiMap = useFieldVisionData("poi", frameNumber, getCurrentTime, assetId, assetTimeOffsetMs, fps, true)
    
    // Mouse overridden POI
    const overriddenMousePoi = editsMap?.get(frameNumber);
    // Smoothed/interpolated POI
    const overriddenSmoothPoi = interpolatedMap?.get(frameNumber);
    // const poiXSmooth = overriddenSmoothPoi?.x || null

    const poiXMouse = override || overriddenSmoothPoi?.x  || overriddenMousePoi?.x || originalPoi?.x || 0.5

    const onDrag = (frame, x, y) => {
        if (!editingPoi) setEditingPoi(true)
        setOverride(x)

        const frameId = originalPoiMap?.get(frame)?.id
        // Object to be submitted if id exists {id:...}
        const idObject = {id: frameId, x:x, y:y}
        // Object to be submitted if id doesn't exist {frameNumber:...} PS: some frames don't have id
        const frameObject = {frameNumber: frame, x:x, y:y}

        const object = frameId ? idObject : frameObject
        editsMap.set(frame, object)
        interpolatedMap.set(frame, object)
    }

    // The function that uses Savitzky-Golay to interpolate the POI
    const interpolatePoi = () => {

        // This runs when user stop dragging/editing, for bridging the gap between the mouse-overridden POI and the original POI
        // to have the poi box gradually return the current original poi box (5% closer to original poi per frame)
        for (let index = 1; index < 20; index++) {
            const lastEntry = Array.from(editsMap?.entries()).pop()
            const lastEditedFrame = lastEntry && lastEntry[0]
            const lastEdited = lastEntry && lastEntry[1]
            const lastOriginalPoi = originalPoiMap.get(lastEditedFrame)
            const lastOriginalX = lastOriginalPoi?.x || 0.5
            if (!lastEdited || !lastOriginalPoi) {
                break
            }
            if (lastEdited.x === lastOriginalX) {
                break
            }
            if (Math.abs(lastEdited.x - lastOriginalX) < 0.05) {
                break
            }
            if ((lastEdited.x - lastOriginalX) > 0.05) {
                editsMap.set(lastEditedFrame + 1, {id: lastOriginalPoi.id, x: lastEdited.x - 0.05, y: lastEdited.y})
            }
            if ((lastEdited.x - lastOriginalX) < -0.05) {
                editsMap.set(lastEditedFrame + 1, {id: lastOriginalPoi.id, x: lastEdited.x + 0.05, y: lastEdited.y})
            }        
        }

        const allKeys = editsMap && Array.from(editsMap.entries()).map(([key, value]) => key)
        const allEdits = editsMap && Array.from(editsMap.entries()).map(([key, value]) => value)
        const editsX = editsMap && Array.from(editsMap.entries()).map(([key, value]) => value.x)
        
        let options = {
            derivative: 0, 
            windowSize: 25, 
            polynomial: 1, 
            pad: "pre",
        }
        const interpolated = savitzkyGolay(editsX, 1, options)

        for (let index = 0; index < allKeys.length; index++) {
            const id = allEdits[index].id
            const frameNumber = allEdits[index].frameNumber
            // Object to be submitted if id exists {id:...}
            const idObject = {id: allEdits[index].id, x: interpolated[index], y: allEdits[index].y}
            // Object to be submitted if id doesn't exist {frameNumber:...} PS: some frames don't have id
            const frameObject = {frameNumber: frameNumber, x: interpolated[index], y: allEdits[index].y}
            const valueObject = id ? idObject : frameObject
            interpolatedMap.set(allKeys[index], valueObject)
        }
        editsMap.clear()
    }

    const onDragEnd = () => {
        setOverride(null)
        setEditingPoi(false)
        interpolatePoi() 
    }

    // Player speed slower to 0.5 when editing the POI
    useEffect(() => {
        if (editingPoi) playerRef.current.playbackRate(0.5)
        else playerRef.current.playbackRate(1)
    }, [editingPoi])

    // This runs when user stop dragging/editing, for bridging the gap between the mouse-overridden POI and the original POI
    // to have the poi box gradually return the current original poi box (5% closer to original poi per frame)
    // useEffect(() => {
    //     if (calculatePoi && !!editsMap) {
    //         for (let index = 1; index < 20; index++) {
    //             const lastEntry = Array.from(editsMap?.entries()).pop()
    //             const lastEditedFrame = lastEntry && lastEntry[0]
    //             const lastEdited = lastEntry && lastEntry[1]
    //             const lastOriginalPoi = originalPoiMap.get(lastEditedFrame)
    //             if (!lastEdited || !lastOriginalPoi) {
    //                 break
    //             }
    //             if (lastEdited.x === lastOriginalPoi.x) {
    //                 break
    //             }
    //             if (Math.abs(lastEdited.x - lastOriginalPoi.x) < 0.05) {
    //                 break
    //             }
    //             if ((lastEdited.x - lastOriginalPoi.x) > 0.05) {
    //                 editsMap.set(lastEditedFrame + 1, {id: lastOriginalPoi.id, x: lastEdited.x - 0.05, y: lastEdited.y})
    //             }
    //             if ((lastEdited.x - lastOriginalPoi.x) < -0.05) {
    //                 editsMap.set(lastEditedFrame + 1, {id: lastOriginalPoi.id, x: lastEdited.x + 0.05, y: lastEdited.y})
    //             }        
    //         }
    //         interpolatePoi()
    //         setCalculatePoi(false)
    //         setOverride(null)
    //     }
    // }, [calculatePoi, editsMap])

    return (
        <>
            <VisualizePOI 
                x={originalPoi.x}
                y={originalPoi.y}
                rectangle={true}
                dimming={!isEditing}
                rectangleColor={isEditing ? "#ff0059" : "#9b9b9b"}
                cross={true}
                aspectRatio={aspectRatio}
                />
            {isEditing && (
                <VisualizePOI
                    x={poiXMouse}
                    y={0}
                    assetTimeOffsetMs={assetTimeOffsetMs}
                    onDrag={onDrag}
                    onDragEnd={onDragEnd}
                    dimming={true}
                    rectangleColor={"#ffd100"}
                    rectangle={true}
                    cross={false}
                    aspectRatio={aspectRatio}
                />
            )}
            {/* Show the original POI */}
            {/* {graphType.includes("original") && (
                <VisualizePOI 
                    x={originalPoi.x}
                    y={originalPoi.y}
                    rectangle={true}
                    dimming={!isEditing}
                    rectangleColor={isEditing ? "#ff0059" : "#9b9b9b"}
                    cross={true}
                    aspectRatio={aspectRatio}
                />
            )}
            {(isEditing && graphType.includes("mouse")) && (
                <VisualizePOI
                    x={poiXMouse}
                    y={0}
                    assetTimeOffsetMs={assetTimeOffsetMs}
                    onDrag={onDrag}
                    onDragEnd={onDragEnd}
                    dimming={true}
                    rectangleColor={"#ffd100"}
                    rectangle={true}
                    cross={false}
                    aspectRatio={aspectRatio}
                />
            )}
            {(isEditing && graphType.includes("smooth") && interpolatedMap.size !== 0 && poiXSmooth) && (
                <VisualizePOI
                    x={poiXSmooth}
                    y={0}
                    dimming={true}
                    rectangleColor={"#2DC30A"}
                    rectangle={true}
                    cross={true}
                    aspectRatio={aspectRatio}
                />
            )} */}
        </>
    )
}

function CropEditingGraph ({
    editsMap, 
    interpolatedMap,
    assetId,
    assetTimeOffsetMs, 
    // selectGraph,
    isEditing,
    timelineStart,
    start,
    end,
}) {

    const {getCurrentTime} = useVideoPlayer()
    const current = getCurrentTime()
    const fps = Config.expectedFrameRate
    const currentFrameActive = useMemo(() => {return secToFrame(current + (assetTimeOffsetMs / 1000), fps)}, [current])
    const frameNumber = secToFrame(getCurrentTime() + (assetTimeOffsetMs / 1000), fps)
    const originalPoiMap = useFieldVisionData("poi", frameNumber, getCurrentTime, assetId, assetTimeOffsetMs, fps, true)
    
    // Selected start and end to be exported (blue bar in the export timeline)
    const selectedStartFrame = useMemo(() => {return secToFrame((timelineStart + start) + (assetTimeOffsetMs / 1000), fps)}, [start])
    const selectedEndFrame = useMemo(() => {return secToFrame((timelineStart + end) + (assetTimeOffsetMs / 1000), fps)}, [end])

    // Frames to be used for the POI graph (original, mouse-overridden, interpolated/smoothed)
    const originalFrames = new Map()
    const mouseOverriddenFrames = new Map()
    const interpolatedFrames = new Map()
    
    // Updating the frames according to the selected start and selected end (blue bar)
    // Showing all the original POI from the selected start to the selected end
    for (let key = selectedStartFrame; key <= selectedEndFrame; key++) {
        originalFrames.set(key, originalPoiMap.get(key))
    }

    // Updating the map that stores mouse overridden/dragged POI
    if (editsMap.size !== 0) {
        // Loop through the frames from selected start to the end
        for (let key = selectedStartFrame; key <= selectedEndFrame; key++) {
            // Only registered/edited frames have the POI, shown in the graph
            if (editsMap.has(key)) {
                mouseOverriddenFrames.set(key, editsMap.get(key))
            // unregistered/unedited frames don't have POI, won't be shown in the graph
            } else {
                mouseOverriddenFrames.set(key, {id: key, x: null, y: null})
            }
        }
    }

    // Updating the map that stores interpolated POI
    if (interpolatedMap.size !== 0) {
        // Loop through the frames from selected start to the end
        for (let key = selectedStartFrame; key <= selectedEndFrame; key++) {
            // Only registered/edited frames have the POI, shown in the graph
            if (interpolatedMap.has(key)) {
                interpolatedFrames.set(key, interpolatedMap.get(key))
            // unregistered/unedited frames don't have POI, won't be shown in the graph
            } else {
                interpolatedFrames.set(key, {id: key, x: null, y: null})
            }
        }
    }

    // For position and distance of every single "x" in the graph (css styling)
    const frameSize = selectedEndFrame - selectedStartFrame
    const frameSpace = (100 / frameSize).toFixed(7)

    const renderOriFrames = useMemo(() => {
        if (originalFrames.size === 0) return null
        return Array.from(originalFrames.entries()).map(([key, value], idx) => {
            // If the frame doesn't have POI at all, it will have default middle position (0.5) with grey color
            const noPoi = !value?.x
            const style = {
                left: (frameSpace * (idx+1)) + "%",
                top: ((value?.x || 0.5) * 100) + "%"
            }
            return <div key={key} style={style} className={classNames("poi-graph-dot", {"no-poi": noPoi})}></div>;
        });
    }, [isEditing, originalFrames, selectedStartFrame, selectedEndFrame])

    const renderEditedFrames = useMemo(() => {
        if (mouseOverriddenFrames.size === 0) return null
        return Array.from(mouseOverriddenFrames.entries()).map(([key, value], idx) => {
            if (!value.x) return null
            else {
                const style = {
                    left: (frameSpace * (idx+1)) + "%",
                    top: (value?.x * 100) + "%"
                }
                return <div key={key} style={style} className="poi-graph-dot yellow"></div>;
            }
        })
    }, [isEditing, mouseOverriddenFrames, selectedStartFrame, selectedEndFrame])

    const renderInterpolatedFrames = useMemo(() => {
        if (interpolatedFrames.size === 0) return null
        return Array.from(interpolatedFrames.entries()).map(([key, value], idx) => {
            if (!value.x) return null
            else {
                const style = {
                    left: (frameSpace * (idx+1)) + "%",
                    top: (value?.x * 100) + "%"
                }
                return <div key={key} style={style} className="poi-graph-dot yellow"></div>;
            }
        })
    }, [isEditing, interpolatedFrames, selectedStartFrame, selectedEndFrame])

    const indicatorStyle = {left: (frameSpace * ((currentFrameActive - selectedStartFrame)+1)) + "%"}
    
    // const loadingIndicator = (
    //     <div className="loading-graph-indicator">
    //         Loading
    //         <span className="loading-dot">.</span>
    //         <span className="loading-dot">.</span>
    //         <span className="loading-dot">.</span>
    //     </div>
    // )

    const graphLinesAndIndicator = (
        <>
            <div style={indicatorStyle} className="poi-graph-indicator"></div>
            {renderOriFrames}
            {renderEditedFrames}
            {renderInterpolatedFrames}
        </>
    )

    return (
        <>
            <div className="poi-graph-cont">
                {/* {originalPoiMap.size === 0 ? loadingIndicator : graphLinesAndIndicator} */}
                {graphLinesAndIndicator}
            </div>
            <EditingTimelineNumber 
                timelineStart={Math.floor(timelineStart + start)} 
                timelineEnd={Math.floor(timelineStart + end)}
                upsideDown
                />
            {/* <div className="graph-type-btns">
                <div className="graph-type-btn">
                    <input 
                        type="checkbox"
                        checked={graphType.includes("original")}
                        onChange={() => selectGraph("original")}
                        />
                    <div>Original</div>
                </div>
                <div className="graph-type-btn">
                    <input 
                        type="checkbox"
                        checked={graphType.includes("mouse")}
                        onChange={() => selectGraph("mouse")}
                        />
                    <div>Mouse (yellow)</div>
                </div>
                <div className="graph-type-btn">
                    <input 
                        type="checkbox"
                        className="graph-type-btn-input"
                        checked={graphType.includes("smooth")}
                        onChange={() => selectGraph("smooth")}
                        />
                    <div>Smooth (green) - only shown after done dragging the poi box</div>
                </div>
            </div> */}
        </>
    )
}

function KeyboardShortcutHandler () {

    const {playerRef} = useVideoPlayer()

    const onKeyPress = (e) => {
        const currentTime = playerRef.current?.currentTime()
        // "F" key
        if (e.keyCode === 82) playerRef.current?.currentTime(Math.max(0, currentTime - 2))
        // "R" key
        if (e.keyCode === 70) playerRef.current?.currentTime(Math.max(0, currentTime + 2))
    }

    useEffect(() => {
        window.addEventListener("keydown", onKeyPress);
        return () => window.removeEventListener("keydown", onKeyPress);
    })
    
    return (
        <div></div>
        // <div className="keyboard-shortcuts-cont">
        //     <div>Keyboard shortcuts</div>
        //     <div>R = Rewind 2 seconds</div>
        //     <div>F = Fast forward 2 seconds</div>
        // </div>
    )
}

export default function CropEditing ({
    timelineStart,
    start,
    end,
}) {
    
    const {playbackUrl} = useVideoPlayer()
    const [aspectRatio, setAspectRatio] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [renderState, setRenderState] = useState(0)
    const [graphType, setGraphType] = useState(["original", "mouse", "smooth"])
    const [resetPoiModalOpen, setResetPoiModalOpen] = useState(false)
    let [assetId, assetTimeOffsetMs] = deconstructPlaybackUrl(playbackUrl)
    
    const fps = Config.expectedFrameRate

    useEffect(() => {
        if (isEditing) setAspectRatio("9/16")
    }, [isEditing])

    // Map of frames that have been edited with a new point of interest
    const editsMap = useMemo(
        () => isEditing ? new Map() : null,
        [assetId, assetTimeOffsetMs, isEditing])

    // Map of frames that have been smoothed with savitzky golay
    const interpolatedMap = useMemo(
        () => isEditing ? new Map() : null,
        [assetId, assetTimeOffsetMs, isEditing])

    // Get the first edited frame
    const timeStamps = playbackUrl.split("/")[5].split(":")
    const clipStartFrame = Math.floor((timeStamps[1]/1000) * fps)
    const clipEndFrame = Math.ceil((timeStamps[2]/1000) * fps)
    
    // Check if the clip has been POI
    // if (!originalPoiMap || originalPoiMap.size === 0) return null

    // Not always loaded on initial render
    if (!assetId) return

    async function onSave () {
        if (interpolatedMap?.size === 0) return
        const editsArray = Array.from(interpolatedMap?.values()) || []
        const poiQuery = {pois: editsArray}
        const {error} = await Backend.put(`/fieldvision/v1/poi/${Config.database}/${assetId}`, JSON.stringify(poiQuery))
        if (error) console.log(error)
        setIsEditing(false)
        setRenderState(renderState + 1)
    }

    async function onReset () {
        const {error} = await Backend.put(
            `/fieldvision/v1/poi/${Config.database}/${assetId}/reset?fromFrame=${clipStartFrame}&toFrame=${clipEndFrame}`,
            "{}",
        )
        if (error) console.log(error)
        setIsEditing(false)
        setRenderState(renderState + 1)
    }

    const selectGraph = (type) => {
        if (graphType.includes(type)) {
            const updatedTypes = graphType.filter((t) => t !== type)
            setGraphType(updatedTypes)
        } else {
            setGraphType([...graphType, type])
        }
    }

    const resetPoiModal = resetPoiModalOpen && (
        <ConfirmModal 
            isOpen
            onClose={() => setResetPoiModalOpen(false)}
            onConfirm={onReset}
            cancelText="Cancel"
            confirmText="Reset"
            >
            <div className="confirm-icon-message">
                <div className="confirm-icon"><RxReset/></div>
                <div className="confirm-title">Reset the Point of Interest of the video to original?</div>
            </div>
        </ConfirmModal>
    )
    
    return (
        <div key={renderState} className="crop-editing-cont">
            <div className="crop-editing-control">
                <div className="crop-editing-aspect">
                    <div>Cropping <span className="beta-version-info">(BETA)</span>:</div>
                    <button type="button"
                            disabled={aspectRatio === null || isEditing}
                            onClick={() => setAspectRatio(null)}
                            className={classNames("cropping-aspect-ratio", {active: aspectRatio === null})}>
                        Off
                    </button>
                    <button type="button"
                            onClick={() => setAspectRatio("1/1")}
                            className={classNames("cropping-aspect-ratio", {active: aspectRatio === "1/1"})}>
                        1:1
                    </button>
                    <button type="button"
                            onClick={() => setAspectRatio("9/16")}
                            className={classNames("cropping-aspect-ratio", {active: aspectRatio === "9/16"})}>
                        9:16
                    </button>
                    {!isEditing && (
                        <button className="crop-editing-edit-btn"
                                type="button"
                                onClick={() => setIsEditing(true)}>
                            <FiEdit3 /> Edit
                        </button>
                    )}
                </div> 
                {isEditing && (
                    <div className="crop-editing-btns">
                        <button className="crop-editing-edit-btn"
                                type="button"
                                onClick={onSave}>
                            <FiSave /> Save
                        </button>
                        <button className="crop-editing-edit-btn"
                            type="button"
                            onClick={() => setResetPoiModalOpen(true)}>
                            Reset
                        </button>
                        <button className="crop-editing-edit-btn"
                                type="button"
                                onClick={() => setIsEditing(false)}>
                            Cancel
                        </button>
                    </div>
                )}
            </div>
            {(aspectRatio || isEditing) && (
                <VideoOverlay className="poi-overlays-cont">
                    <PoiOverlays
                        editsMap={editsMap}
                        interpolatedMap={interpolatedMap}
                        isEditing={isEditing}
                        aspectRatio={aspectRatio || "9/16"}
                        assetId={assetId}
                        assetTimeOffsetMs={assetTimeOffsetMs} 
                        graphType={graphType}
                        />
                </VideoOverlay>
            )}
            {isEditing && (
                <CropEditingGraph
                    editsMap={editsMap}
                    interpolatedMap={interpolatedMap}
                    assetId={assetId}
                    assetTimeOffsetMs={assetTimeOffsetMs}
                    graphType={graphType}
                    selectGraph={selectGraph}
                    isEditing={isEditing}
                    timelineStart={timelineStart}
                    start={start}
                    end={end}
                    />
            )}
            {isEditing && (
                <KeyboardShortcutHandler/>
            )}
            {resetPoiModal}
        </div>
    )
}