import "./EditPlaylist.css";
import React, {useState} from "react";
import Page, {ErrorPage, LoadingPage} from "../../../components/Page";
import {useBackend} from "../../../utility/Backend";
import {useSWRConfig} from "swr";
import PlaylistMetadataSection from "./PlaylistMetadataSection";
import EventMetadataSection from "./EventMetadataSection";
import {Helmet} from "react-helmet-async";
import {useLoginSession} from "../../../stores/loginSession";
import Config from "../../../utility/Config";
import { hockeyTimeFormat } from "../../../utility/Utilities";

function makeTitle (event) {
    if (!event.playlist) return event.id;
    if (!event.playlist.game) return event.id;

    const isShl = Config.association === "SHL"

    const g = event.playlist.game;
    const home = g.home_team;
    const away = g.visiting_team;
    const time = Math.ceil(event.game_time / 60);
    const shlGameTime = isShl && hockeyTimeFormat(event.tag)
    const gameTime = isShl? shlGameTime : time
    let type = event.tag.action;
    if (type === "goal") type += ` (${event.score})`;
    return `${home.short_name} - ${away.short_name}, ${type}, ${gameTime}'`;
}

function EditEvent ({event, onMutate}) {

    const playlist = event.playlist;
    const gameUrl = playlist ? null : "/game/" + event.game_id;
    const {data: gameData} = useBackend(gameUrl);
    const game = gameData ? gameData : (playlist ? playlist.game : null);
    const {token} = useLoginSession();

    // TODO hide event metadata when creating subclip
    const [hideEventMetadata, setHideEventMetadata] = useState(false)

    const {data: playlistData, mutate: mutatePlaylist} = useBackend(
        playlist ? ("/playlist/" + playlist.id) : null,
        {include_all_usergroups:true, include_featured_flag: true, include_channels: true, access_token: token},
        {fallbackData: playlist});

    return (
        <Page title={makeTitle(event, playlistData)}>
            <Helmet>
                <title>{playlist ? playlist.description : event.id}</title>
            </Helmet>
            <div className="edit-playlist-container">
                {playlistData && (
                    <PlaylistMetadataSection key={playlistData.id}
                                             onMutate={(d) => mutatePlaylist(d)}
                                             event={event}
                                             playlist={playlistData} 
                                             setHideEventMetadata={setHideEventMetadata} />
                )}
                <EventMetadataSection event={event} game={game} onMutate={onMutate} hide={hideEventMetadata}/>
            </div>
        </Page>
    );
}

export default function EditEventWrapper ({id}) {
    // We use the cache purely to get instant rendering when clicking on a search entry
    const {cache} = useSWRConfig();
    let fallback = cache.get("editing_event");
    if (fallback && fallback.id !== id) fallback = undefined;

    const {data, error, mutate} = useBackend(
        "/event/" + id,
        undefined,
        {fallbackData: fallback});

    if (data) return <EditEvent event={data} onMutate={(d) => mutate(d)}/>

    if (error) return (
        <ErrorPage title="Editing" >
            {(error.status === 404) && (
                <span>Event {id} could not be found</span>
            )}
            {(error.status !== 404) && (
                <span>Unknown error occurred trying to fetch data from server</span>
            )}
        </ErrorPage>
    );

    return <LoadingPage title="Editing" />;
}