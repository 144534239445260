import {BsAlignEnd, BsAlignStart, BsZoomIn, BsZoomOut} from "react-icons/bs";
import {capitalizeFirstLetter, formatTimecode, getTimelineClickRatio, throttled} from "../../../../utility/Utilities";
import React, {useState, useRef} from "react";
import "./EditingControls.css";
import classNames from "classnames";
import EditingTimelineNumber from "./EditingTimelineNumber";
// import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { TiArrowSortedUp } from "react-icons/ti";

export default function EditingTimeline ({
    event=null,
    timelineStart, // Start of the visual timeline in seconds into the original video
    timelineEnd, // End of the visual timeline in seconds into the original video
    start, // Start of selection
    end, // End of selection
    skipStart=0,
    skipEnd=0,
    originalStartEndPoint,
    masterVideoAsset=false,
    setStart,
    setEnd,
    setSkipStart,
    setSkipEnd,
    getCurrentPlaybackTime, // Get the timestamp from the video player for playback location
    seekTo, // Function for seeking to a given second into the original video
    zoomIn = undefined, // Function for reducing the size of the timeline
    zoomOut = undefined, // Function for increasing the size of the timeline
    type="clip",
}) {

    const editingTimelineRef = useRef(null)

    const [editingStart, setEditingStart] = useState(false)
    const [editingEnd, setEditingEnd] = useState(false)
    const [editingSkipStart, setEditingSkipStart] = useState(false)
    const [editingSkipEnd, setEditingSkipEnd] = useState(false)

    const intervalDuration = (end - start) - (skipEnd - skipStart)
    const timelineDuration = timelineEnd - timelineStart;
    const startRatio = Math.min(1, start / timelineDuration);
    const endRatio = Math.min(1, end / timelineDuration);
    const style = {left: `${100*startRatio}%`, width: `${100 * (endRatio - startRatio)}%`};
    
    const skipStartRatio = Math.min(1, skipStart / timelineDuration);
    const skipEndRatio = Math.min(1, skipEnd / timelineDuration);
    const skipSegmentStyle = {left: `${100*skipStartRatio}%`, width: `${100 * (skipEndRatio - skipStartRatio)}%`};

    const originalStartRatio = Math.min(1, originalStartEndPoint?.start / timelineDuration) * 100
    const originalEndRatio = Math.min(1, originalStartEndPoint?.end / timelineDuration) * 100
    const originalStartStyle = masterVideoAsset ? {display: "none"} : {left: `${originalStartRatio}%`} 
    const originalEndStyle = masterVideoAsset ? {display: "none"} : {left: `${originalEndRatio}%`} 
    
    const time = getCurrentPlaybackTime()
    // disable set start button when it reaches 2 seconds before the end of timeline
    const startMaxLimit = (timelineEnd - time < 2) && (timelineEnd - time >= 0)
    // disable set end button it reaches 2 seconds after the start of timeline
    const endMinLimit = (timelineStart - time < 2) && (timelineStart - time >= 0)

    const handleSetStartPoint = () => {
        if (startMaxLimit) return
        else setStart(time)
    }

    const handleSetEndPoint = () => {
        if (endMinLimit) return
        else setEnd(time)
    }

    function timelineMouseDown (e, settingStartPoint=false) {
        e.preventDefault();
        // Targeting the timeline ref
        const target = editingTimelineRef?.current
        const settingEndPoint = !settingStartPoint
        const func = settingStartPoint ? setStart : setEnd;

        if (settingStartPoint) setEditingStart(true)
        else setEditingEnd(true)

        const onMouseMove = throttled((e) => {
            let sec = timelineStart + (getTimelineClickRatio(e, target) * timelineDuration);
            sec = settingEndPoint ? Math.min(sec, timelineEnd) : sec;
            const stopStart = settingStartPoint && timelineEnd - sec < 2
            const stopEnd = settingEndPoint && sec - timelineStart < 2
            if (stopStart || stopEnd) return
            seekTo(sec);
            func(sec);
        }, 200);

        onMouseMove(e);

        const onMouseUp = () => {
            setEditingStart(false)
            setEditingEnd(false)
            window.removeEventListener("mousemove", onMouseMove)
            window.removeEventListener("mouseup", onMouseUp)
        };
        window.addEventListener("mouseup", onMouseUp);
        window.addEventListener("mousemove", onMouseMove);
    }

    function timelineMouseDownSkip (e, settingStartPoint=false) {
        e.preventDefault();
        // Targeting the timeline ref
        const target = editingTimelineRef?.current
        const settingEndPoint = !settingStartPoint
        const func = settingStartPoint ? setSkipStart : setSkipEnd;

        if (settingStartPoint) setEditingSkipStart(true)
        else setEditingSkipEnd(true)

        const onMouseMove = throttled((e) => {
            let sec = timelineStart + (getTimelineClickRatio(e, target) * timelineDuration);
            sec = settingEndPoint ? Math.min(sec, timelineEnd) : sec;
            const stopSkipStart = settingStartPoint && sec - start < 1
            const stopSkipEnd = settingEndPoint && end - sec < 1
            if (stopSkipStart || stopSkipEnd) return
            seekTo(sec);
            func(sec);
        }, 200);

        onMouseMove(e);

        const onMouseUp = () => {
            setEditingSkipStart(false)
            setEditingSkipEnd(false)
            window.removeEventListener("mousemove", onMouseMove)
            window.removeEventListener("mouseup", onMouseUp)
        };
        window.addEventListener("mouseup", onMouseUp);
        window.addEventListener("mousemove", onMouseMove);
    }

    const onSeekTo = (e) => {
        e.preventDefault();
        // Targeting the timeline ref
        const target = editingTimelineRef?.current
        let sec = timelineStart + (getTimelineClickRatio(e, target) * timelineDuration);
        seekTo(sec)
    }

    return (
        <>
            <EditingTimelineNumber timelineStart={timelineStart} timelineEnd={timelineEnd}/>
            <div ref={editingTimelineRef} onMouseDown={onSeekTo} className="editing-timeline">
                <div className="editing-timeline-interval" style={style} >
                    <div 
                        onMouseDown={(e) => timelineMouseDown(e, true)} 
                        onMouseOver={() => setEditingStart(true)} 
                        onMouseLeave={() => setEditingStart(false)}
                        className={classNames("editing-start", {"active": editingStart})}
                        />
                    <div 
                        onMouseDown={(e) => timelineMouseDown(e)} 
                        onMouseOver={() => setEditingEnd(true)} 
                        onMouseLeave={() => setEditingEnd(false)}
                        className={classNames("editing-end", {"active": editingEnd})}
                        />
                </div>
                {(skipStart && skipEnd) && (
                    <div className="editing-timeline-interval-skip" style={skipSegmentStyle}>
                        <div 
                            onMouseDown={(e) => timelineMouseDownSkip(e, true)} 
                            onMouseOver={() => setEditingSkipStart(true)} 
                            onMouseLeave={() => setEditingSkipStart(false)}
                            className={classNames("editing-skip-start", {"active": editingSkipStart})}
                            />
                        <div 
                            onMouseDown={(e) => timelineMouseDownSkip(e)} 
                            onMouseOver={() => setEditingSkipEnd(true)} 
                            onMouseLeave={() => setEditingSkipEnd(false)}
                            className={classNames("editing-skip-end", {"active": editingSkipEnd})}
                            />
                    </div>
                )}
                {/* {event && <div style={goalClockStyle} onClick={() => seekTo(originalStartEndPoint.start + recordingToClockDiff)} className="wall-clock-indicator"></div>}
                {event && <div style={peakIndicatorStyle} onClick={() => seekTo(peakIndicator)} className="peak-indicator"></div>} */}
            </div>
            {/* <div className="original-start-and-end">
                <div data-tooltip="Original start point" style={originalStartStyle} className="original-start-indicator">
                    <TiArrowSortedUp/>
                </div>
                <div data-tooltip="Original end endpoint" style={originalEndStyle} className="original-end-indicator">
                    <TiArrowSortedUp/>
                </div>
            </div> */}
            <div className={classNames("editing-interval-controls", {"zoom": zoomIn && zoomOut})}>
                {zoomOut && (
                    <button className="icon-button"
                        title="Zoom Out"
                        onClick={zoomOut}>
                        <BsZoomOut />
                    </button>
                )}
                <div className="editing-interval-and-button">
                    <div className="editing-interval-time">
                        <div>{capitalizeFirstLetter(type)} start time</div>
                        {formatTimecode(timelineStart+start, true)}
                    </div>
                    <button 
                        disabled={startMaxLimit}
                        data-tooltip={`Set ${type} end`}
                        className="icon-button"
                        onClick={handleSetStartPoint}>
                        <BsAlignStart />
                    </button>
                </div>
                <div className="editing-interval-time">
                    <div>{capitalizeFirstLetter(type)} duration</div>
                    {formatTimecode(intervalDuration, true)}
                </div>
                <div className="editing-interval-and-button">
                    <button 
                        disabled={endMinLimit}
                        data-tooltip={`Set ${type} end`}
                        className="icon-button"
                        onClick={handleSetEndPoint}>
                        <BsAlignEnd />
                    </button>
                    <div className="editing-interval-time">
                        <div>{capitalizeFirstLetter(type)} end time</div>
                        {formatTimecode(timelineStart+end, true)}
                    </div>
                </div>
                {zoomIn && (
                    <button className="icon-button"
                            title="Zoom in"
                            onClick={zoomIn}>
                        <BsZoomIn />
                    </button>
                )}
            </div>
        </>
    );
}