import "./EditingControls.css";
import "../../../../components/css/ListingFilter.css";
import { useBackend } from "../../../../utility/Backend";
import Config from "../../../../utility/Config";
import {useVideoPlayer} from "../../../../components/VideoPlayer/VideoPlayerProvider";
import React, {useState, useEffect, useReducer, useMemo} from "react";
import EditingTimeline from "./EditingTimeline";
import { createPlaybackUrl } from "../../../../utility/Utilities";
import DirectDownload from "../../../../components/DirectDownload";
import PrepareExport from "./PrepareExport";
import ExportGoal from "./ExportGoal";
import { ImInfo } from "react-icons/im";
import { RxReset } from "react-icons/rx";
import classNames from "classnames";
import CropEditing from "./CropEditing/CropEditing";

// TODO Future development: make this reducer to return values in milliseconds
function initNewExportClipReducer ({getCurrentTime, duration, playlist=null, newEndPoint=false}) {
    
    const masterVideoAsset = !!playlist?.master_videoasset
    const masterHasPreRoll = masterVideoAsset && playlist.prerolls.length !== 0

    // TODO probably need to make a config for this
    const suggestedDuration = 90
    let current = 0
    if (masterVideoAsset) {
        current = newEndPoint ? Math.floor(getCurrentTime()) - suggestedDuration : Math.floor(getCurrentTime())
        current = Math.max(0, current)
    }

    let subtractPadding = 0
    // TODO cleaner way for handling video/master that has preroll
    let preRoll = 0
    const totalExceedMaximumExport = Config.maximumExportSeconds < (Config.paddingDuringExport*2) + suggestedDuration
    // if both start and end padding plus suggested duration exceed the total export seconds 
    // then padding subtract middle of suggested duration (f.ex 450 secs - 45 secs)
    if (totalExceedMaximumExport) subtractPadding = suggestedDuration/2
    // Need rounding because the subclip reducer and timeline number are using second 
    if (masterHasPreRoll) preRoll = Math.round(playlist.prerolls.reduce((agg, p) => agg + p["duration_ms"], 0) / 1000)

    const paddingRange = Config.paddingDuringExport - subtractPadding

    const timelineStart = Math.max(0, current - paddingRange - preRoll)
    const timelineEnd = Math.min(duration, timelineStart + (paddingRange*2) + suggestedDuration + preRoll)
    const start = Math.max(0, current - preRoll);
    const end = Math.min(duration, current + suggestedDuration);

    return {
        duration,
        timelineStart,
        timelineEnd,
        start: start - timelineStart,
        end: end - timelineStart,
        skipStart: null,
        skipEnd: null,
    }
}

export default function ExportClip ({event, playlist, onDone}) {
    
    let {
        playing,
        setPlaying,
        playbackUrl,
        switchPlayback,
        resetPlaybackUrl,
        duration,
        seekTo,
        getCurrentTime,
    } = useVideoPlayer();

    if (duration === 0) duration = 0.0001; // Super edge case
    
    // If someday export wanna have magnifying glasses, this reducer function needs to be like in create subClip
    function newExportClipReducer (state, action) {

        let {duration: stateDuration, timelineStart, timelineEnd, start, end, skipStart, skipEnd} = state;

        switch (action.type) {
            case "reset": {
                return initNewExportClipReducer(action.props);
            }
            case "setStart": {
                start = action.position - timelineStart;
                end = Math.max(end, start + 2)
                const startExceedsTimeline = action.position < timelineStart || action.position > timelineEnd
                // back to default suggestion, like the first time hitting export
                if (startExceedsTimeline) {
                    return initNewExportClipReducer({getCurrentTime, duration, playlist})
                }
                if ((skipStart - start) <= 1) {
                    skipStart = start + 1
                }
                return {stateDuration, timelineStart, timelineEnd, start, end, skipStart, skipEnd};
            }
            case "setEnd": {
                end = action.position - timelineStart;
                start = Math.min(start, end - 2)
                const endExceedsTimeline = action.position < timelineStart || action.position > timelineEnd
                // back to default suggestion, like the first time hitting export, except this one is setting end point
                // so sending newEndPoint prop to reducer so that the reducer know it must subtract the current time with suggested duration
                if (endExceedsTimeline) {
                    const newEndPoint = true
                    return initNewExportClipReducer({getCurrentTime, duration, playlist, newEndPoint})
                }
                if ((end - skipEnd) <= 1) {
                    skipEnd = end - 1
                }
                return {stateDuration, timelineStart, timelineEnd, start, end, skipStart, skipEnd};
            }
            case "setSkipStart": {
                skipStart = action.position - timelineStart;
                // skipEnd = Math.max(end, skipStart + 2)
                return {stateDuration, timelineStart, timelineEnd, start, end, skipStart, skipEnd};
            }
            case "setSkipEnd": {
                skipEnd = action.position - timelineStart;
                // skipStart = Math.min(start, end - 2)
                return {stateDuration, timelineStart, timelineEnd, start, end, skipStart, skipEnd};
            }
            case "resetSkip": {
                skipStart = null
                skipEnd = null
                return {stateDuration, timelineStart, timelineEnd, start, end, skipStart, skipEnd};
            }
            default: throw new Error("Unsupported reducer action " + action.type);
        }
    }

    const masterVideoAsset = !!playlist.master_videoasset
    const masterHasPreRoll = masterVideoAsset && playlist.prerolls.length !== 0

    const [state, dispatch] = useReducer(newExportClipReducer, {getCurrentTime, duration, playlist}, initNewExportClipReducer);
    const {start, end, timelineStart, timelineEnd, skipStart, skipEnd} = state;
    
    // first start & end point when starting an export
    const [originalStartEndPoint, setOriginalStartEndPoint] = useState(null)
    // check if user has edited the blue timeline
    const timelineHasChanged = useMemo(() => {
        return originalStartEndPoint && (originalStartEndPoint.start !== start || originalStartEndPoint.end !== end)
    }, [start, end, originalStartEndPoint])

    const hasSkipSegment = skipStart && skipEnd

    const showResetButton = timelineHasChanged || hasSkipSegment
    
    const clip = playlist.events[0]
    const timeStart = timelineStart + start
    const timeEnd = timelineStart + end
    const splitUrl = playbackUrl.split("/");
    const [,offsetMs] = splitUrl[splitUrl.length - 2].split(":").map(e => parseInt(e));
    const fromTimestamp = playlist?.is_live ? parseInt(timeStart * 1000) : parseInt(offsetMs + (timeStart * 1000))
    const toTimestamp = playlist?.is_live ? parseInt(timeEnd * 1000) : parseInt(offsetMs + (timeEnd * 1000))
    const clipDuration = playlist.duration_ms/1000
    const skipFromTimestamp = skipStart ? parseInt(offsetMs + ((timelineStart + skipStart) * 1000)) : null
    const skipToTimestamp = skipEnd ? parseInt(offsetMs + ((timelineStart + skipEnd) * 1000)) : null

    const originalFromTimestamp = playlist?.events[0].from_timestamp
    const originalToTimestamp = playlist?.events[0].to_timestamp
    const originalVideoAssetId = playlist?.events[0].video_asset_id
    
    // SHL
    const isShl = Config.association === "SHL"
    const isShlGoal = isShl && event?.tag.action === "goal"
    const [skipSegment, setSkipSegment] = useState(false)

    // useEffect(() => {
    //     if (!skipStart || !skipEnd) return
    //     const currentTime = getCurrentTime()
    //     if (Math.abs(skipStart - currentTime) <= 0.1) {
    //         seekTo(skipEnd)
    //     }
    // })

    // first blue timeline start suggestion
    const firstStartPoint = useMemo(() => start, [])
    // the position in video player when user click export
    const startTimestamp = useMemo(() => getCurrentTime(), [])

    const {data: asset} = useBackend("/asset/" + clip.video_asset_id)

    // Non master asset export
    const startExport = async () => {
        let paddingRange = Config.paddingDuringExport - clipDuration/2
        if (paddingRange < 0) paddingRange = Config.paddingDuringExport
        const {duration, url} = createPlaybackUrl(clip, playbackUrl, paddingRange, 0, asset?.duration_ms);
        console.log("startEditingExistingClip", url);
        await switchPlayback(url, duration);

        const clipStartPoint = Math.max(0, clip.from_timestamp/1000)
        const start = Math.min(clipStartPoint, Math.max(0, paddingRange))
        const end = Math.min(start + clipDuration, start + 300)
        dispatch({type: "reset", props: {getCurrentTime, duration}});
        dispatch({type: "setStart", position: start})
        dispatch({type: "setEnd", position: end})
        setOriginalStartEndPoint({start, end})
        seekTo(start)
    }

    // won't call this function if the video is a master asset (asset has original full timestamp)
    useEffect(() => {
        if (!masterVideoAsset) startExport()
    }, []);

    // because master video with preroll always stops when export, then need to have the player to jump to where the export starts
    useEffect(() => {
        if (masterHasPreRoll) seekTo(timelineStart + start)
    }, [])

    useEffect(() => {
        if (playing && (firstStartPoint !== start)) setPlaying(false)
    }, [start, end])
    
    function onBeforeCancel () {
        resetPlaybackUrl()
        onDone()
        setTimeout(() => seekTo(startTimestamp), 1)
    }

    function resetExportClipDuration () {
        if (timelineHasChanged) {
            dispatch({type: "setStart", position: originalStartEndPoint.start})
            dispatch({type: "setEnd", position: originalStartEndPoint.end})
        }
        if (skipSegment) {
            dispatch({type: "resetSkip"})
            setSkipSegment(false)
        }
    }

    const onHandleSkipSegment = () => {
        if (skipSegment) {
            dispatch({type: "resetSkip"})
            setSkipSegment(false)
        } else {
            const middleDurationAsset = (fromTimestamp + (toTimestamp - fromTimestamp)/2) / 1000
            const middleDurationClip = start + (end-start)/2
            const middleDuration = masterVideoAsset ? middleDurationAsset : middleDurationClip
            const skipStart = middleDuration - 3
            const skipEnd = middleDuration + 3
            dispatch({type: "setSkipStart", position: skipStart})
            dispatch({type: "setSkipEnd", position: skipEnd})
            setSkipSegment(true)
        }
    }
    
    const originalClipTimestamp = useMemo(() => {
        return ({
            from_timestamp: originalFromTimestamp,
            to_timestamp: originalToTimestamp,
            video_asset_id: originalVideoAssetId
        })
    }, [playlist])

    // clip that has selected blue timeline start & end range for creating the url for direct download
    const trimmedClipTimestamp = useMemo (() => {
        return ({
            from_timestamp: fromTimestamp,
            to_timestamp: toTimestamp,
            video_asset_id: originalVideoAssetId
        })
    }, [fromTimestamp, toTimestamp, originalVideoAssetId])

    let clipForDownloadUrl
    // if master asset, no need to reset back to original duration (like single event duration)
    if (masterVideoAsset) clipForDownloadUrl = trimmedClipTimestamp
    else clipForDownloadUrl = timelineHasChanged ? trimmedClipTimestamp : originalClipTimestamp

    const downloadFileName = `${originalVideoAssetId}-${fromTimestamp}-${toTimestamp}`
    const {url} = useMemo(() => (createPlaybackUrl(clipForDownloadUrl)), [clipForDownloadUrl])

    const downloadTips = (
        <div className="download-tips">
            <div className="download-tips-icon">
                <ImInfo/>
            </div>
            <div>
                <div>Direct download is faster, but supported formats may not be suitable for all purposes. If one does not work, try another.
                    If you need any transformations or an all-purpose .mp4, use Prepared Export.
                </div>
            </div>
        </div>
    )

    return (
        <>
            <EditingTimeline
                event={event}
                timelineStart={timelineStart}
                timelineEnd={timelineEnd}
                start={start}
                end={end}
                skipStart={skipStart}
                skipEnd={skipEnd}
                setStart={(s) => dispatch({type: "setStart", position: s})}
                setEnd={(s) => dispatch({type: "setEnd", position: s})}
                setSkipStart={(s) => dispatch({type: "setSkipStart", position: s})}
                setSkipEnd={(s) => dispatch({type: "setSkipEnd", position: s})}
                getCurrentPlaybackTime={getCurrentTime}
                seekTo={seekTo}
                timelineHasChanged={timelineHasChanged}
                originalStartEndPoint={originalStartEndPoint}
                skipSegment={skipSegment}
                masterVideoAsset={masterVideoAsset}
                type="export"
            />
            <div onClick={resetExportClipDuration} className={classNames("export-timeline-reset", {"show": showResetButton})}>
                <RxReset/> Reset to original
            </div>
            <div className="skip-segment-button">
                <input 
                    type="checkbox"
                    checked={skipSegment}
                    onChange={onHandleSkipSegment}
                    />
                <div>Skip segment <span className="beta-version-info">(BETA)</span></div>
            </div>
            {isShlGoal && (
                <ExportGoal 
                    event={event}
                    setStart={(s) => dispatch({type: "setStart", position: s})}
                    setEnd={(s) => dispatch({type: "setEnd", position: s})}
                    seekTo={seekTo}
                    setSkipSegment={setSkipSegment}
                    setSkipStart={(s) => dispatch({type: "setSkipStart", position: s})}
                    setSkipEnd={(s) => dispatch({type: "setSkipEnd", position: s})}
                    originalStartEndPoint={originalStartEndPoint}
                    />
            )}
            <br />
            {playlist.events.length === 1 && (
                <CropEditing
                    timelineStart={timelineStart}
                    start={start}
                    end={end}
                    />
            )}
            <div className="confirm-cancel-btn-cont between">
                <div className="download-export-btn">
                    <DirectDownload
                        filename={downloadFileName}
                        videoUrl={url}
                        duration={(toTimestamp-fromTimestamp)/1000}                   
                        />
                    <PrepareExport 
                        playlist={playlist} 
                        fromTimestamp={fromTimestamp}
                        toTimestamp={toTimestamp}
                        skipFromTimestamp={skipFromTimestamp}
                        skipToTimestamp={skipToTimestamp}
                        onResetPlayback={onBeforeCancel}
                        />
                </div>
                <button onClick={onBeforeCancel}>
                    Cancel
                </button>
            </div>
            {downloadTips}
        </>
    );
}